<nav class="ui menu" v-if="!$route.path.startsWith('/embed')"><router-link class="item" to="/"><i class="home icon"></i>自學是什麼</router-link><router-link class="item" to="/faq"><i class="question circle icon"></i>問答集</router-link><router-link class="item" to="/four"><i class="tasks icon"></i>四階段</router-link><router-link class="item fat-only" to="/outer"><i class="book icon"></i>資源推薦</router-link><router-link class="item" to="/github"><i class="github icon"></i><span class="fat-only">本專案源碼</span></router-link></nav><!-- .marquee-container.marquee(:style="marqueeStyle", ref="marquee")
  .marquee-content
    router-link.item(
      v-for="(item, index) in faqs",
      :key="index",
      :to="'/ans/' + index"
    ) {{ parse(item.q) }}
  .marquee-content(aria-hidden="true")
    router-link.item(
      v-for="(item, index) in faqs",
      :key="index",
      :to="'/ans/' + index"
    ) {{ parse(item.q) }}
--><router-view :faqs="faqs"></router-view><ad class="fat-only" v-if="!$route.path.startsWith('/embed')"></ad>